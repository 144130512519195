var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    {
      attrs: {
        show: _vm.fetching,
        variant: "white",
        opacity: "0.4",
        "spinner-variant": "primary",
      },
    },
    [
      _c(
        "b-table-simple",
        {
          staticClass: "bordered",
          attrs: { hover: "", responsive: "", striped: "" },
        },
        [
          _c(
            "b-thead",
            [
              _c(
                "b-tr",
                _vm._l(_vm.fields, function (field) {
                  return _c(
                    "b-th",
                    {
                      key: field.key,
                      class: field.thClass || "",
                      style: field.thStyle || {},
                    },
                    [_vm._v(" " + _vm._s(field.label) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "b-tbody",
            [
              _vm.localItems.length === 0
                ? [
                    _c(
                      "b-tr",
                      [
                        _c(
                          "b-td",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: _vm.fields.length },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.emptyText || _vm.$t("Nenhum Registro")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._l(_vm.localItems, function (item, itemIndex) {
                return [
                  _c(
                    "b-tr",
                    {
                      key: "item_" + itemIndex,
                      on: {
                        click: function ($event) {
                          return _vm.onClickRow(item)
                        },
                      },
                    },
                    [
                      _vm._l(_vm.fields, function (field, fieldIndex) {
                        return [
                          _c(
                            "b-td",
                            {
                              key: "detail_cell-" + field.key,
                              class: field.detailTdClass || field.tdClass || "",
                              style: field.detailTdStyle || field.tdStyle || {},
                            },
                            [
                              fieldIndex === 0
                                ? _c("b-icon", {
                                    attrs: {
                                      icon: item.isRowSelected
                                        ? "chevron-up"
                                        : "chevron-down",
                                      variant: "primary",
                                    },
                                  })
                                : _vm._e(),
                              _vm._t(
                                "detail-cell(" + field.key + ")",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getValueProp(
                                          item,
                                          field.detailKey
                                        ) || ""
                                      ) +
                                      " "
                                  ),
                                ],
                                { item: item }
                              ),
                            ],
                            2
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  item.isRowSelected
                    ? _vm._l(
                        item[_vm.nestedListProperty],
                        function (itemNested, itemNestedIndex) {
                          return _c(
                            "b-tr",
                            {
                              key:
                                "item_" +
                                itemIndex +
                                "-item_nested_" +
                                itemNestedIndex,
                              staticStyle: {
                                "background-color": "transparent !important",
                                cursor: "auto",
                              },
                            },
                            [
                              _vm._l(_vm.fields, function (field) {
                                return [
                                  _c(
                                    "b-td",
                                    {
                                      key: "nested_cell-" + field.key,
                                      class: field.tdClass || "",
                                      staticStyle: { width: "auto" },
                                      style: field.tdStyle || {},
                                    },
                                    [
                                      _vm._t(
                                        "nested-cell(" + field.key + ")",
                                        [
                                          field.filter
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$options.filters[
                                                        field.filter
                                                      ](
                                                        _vm.getValueProp(
                                                          itemNested,
                                                          field.key
                                                        )
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getValueProp(
                                                        itemNested,
                                                        field.key
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        { itemNested: itemNested }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              }),
                            ],
                            2
                          )
                        }
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm._t("custom-foot"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }