<template>
  <b-overlay
    :show="fetching"
    variant="white"
    opacity="0.4"
    spinner-variant="primary"
  >
    <b-table-simple
      hover
      responsive
      striped
      class="bordered"
    >
      <b-thead>
        <b-tr>
          <b-th
            v-for="field in fields"
            :key="field.key"
            :class="field.thClass || ''"
            :style="field.thStyle || {}"
          >
            {{ field.label }}
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <template v-if="localItems.length === 0">
          <b-tr>
            <b-td
              class="text-center"
              :colspan="fields.length"
            >
              {{ emptyText || $t('Nenhum Registro') }}
            </b-td>
          </b-tr>
        </template>

        <!-- loop com o template -->
        <template v-for="(item, itemIndex) in localItems">
          <!-- tr desrespeitando colunas, com detalhes formatados -->
          <b-tr
            :key="`item_${itemIndex}`"
            @click="onClickRow(item)"
          >
            <template v-for="(field, fieldIndex) in fields">
              <b-td
                :key="`detail_cell-${field.key}`"
                :class="field.detailTdClass || field.tdClass || ''"
                :style="field.detailTdStyle || field.tdStyle || {}"
              >
                <b-icon
                  v-if="fieldIndex === 0"
                  :icon="item.isRowSelected ? 'chevron-up' : 'chevron-down'"
                  variant="primary"
                />
                <slot
                  :name="`detail-cell(${field.key})`"
                  :item="item"
                >
                  {{ getValueProp(item, field.detailKey) || '' }}
                </slot>
              </b-td>
            </template>
          </b-tr>

          <!-- tr respeitando colunas (loop em itens da compra) -->
          <template v-if="item.isRowSelected">
            <b-tr
              v-for="(itemNested, itemNestedIndex) in item[nestedListProperty]"
              :key="`item_${itemIndex}-item_nested_${itemNestedIndex}`"
              style="background-color: transparent !important; cursor: auto"
            >
              <template v-for="field in fields">
                <b-td
                  :key="`nested_cell-${field.key}`"
                  :class="field.tdClass || ''"
                  :style="field.tdStyle || {}"
                  style="width: auto"
                >
                  <slot
                    :name="`nested-cell(${field.key})`"
                    :item-nested="itemNested"
                  >
                    <template v-if="field.filter">
                      {{ $options.filters[field.filter](getValueProp(itemNested, field.key)) }}
                    </template>
                    <template v-else>
                      {{ getValueProp(itemNested, field.key) }}
                    </template>
                  </slot>
                </b-td>
              </template>
            </b-tr>
          </template>
        </template>
      </b-tbody>
      <slot name="custom-foot" />
    </b-table-simple>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { BTableSimple, BThead, BTr, BTh, BTbody, BTd, BIcon, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BIcon,
    BOverlay,
  },

  props: {
    /**
     * list of objects with:
     * {
     *    detailKey: string,
     *    detailTdClass: string,
     *    detailTdStyle: cssObject,
     *    key: string,
     *    label: string,
     *    thStyle: cssObject,
     *    thClass: string,
     *    tdStyle: cssObject,
     *    tdClass: string,
     *    filter: string, // vue filter ex: currency, datetime, etc
     * }
     */
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    nestedListProperty: {
      type: String,
      required: true,
    },

    emptyText: {
      type: String,
      default: '',
    },

    fetching: {
      type: Boolean,
      default: false,
    },

    // iniciar todos os registros abertos
    initColapsed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localItems: [],
    }
  },

  watch: {
    items() {
      this.localItems = this.items
    },
  },

  mounted() {
    if (this.initColapsed) {
      this.localItems = this.items.map(i => ({
        ...i,
        isRowSelected: true,
      }))
    } else {
      this.localItems = this.items
    }
  },

  methods: {
    onClickRow(item) {
      // eslint-disable-next-line no-param-reassign
      item.isRowSelected = !item.isRowSelected
      this.localItems = [...this.localItems]
    },

    getValueProp(obj, propPath) {
      return obj[propPath] || _.get(obj, propPath)
    },
  },
}
</script>

<style lang="scss" scoped></style>
